import { Vue, Component } from 'vue-property-decorator';
import Logo from '@/components/common/logo/Logo.vue';
// eslint-disable-next-line import/extensions
import FacebookIcon from '@/assets/icons/facebook.svg?inline';
// eslint-disable-next-line import/extensions
import TwitterIcon from '@/assets/icons/twitter.svg?inline';
// eslint-disable-next-line import/extensions
import LinkedInIcon from '@/assets/icons/linkedin.svg?inline';
// eslint-disable-next-line import/extensions
import InstagramIcon from '@/assets/icons/instagram.svg?inline';

const { email, phone } = require('../../../../whitelabel.config');

@Component({
  components: {
    Logo,
    FacebookIcon,
    TwitterIcon,
    LinkedInIcon,
    InstagramIcon,
  },
})

export default class Footer extends Vue {
  currentYear: number = new Date().getFullYear();
  email = email;
  phone = phone;

  /**
   * Get copyright statement. Use 'en dash'
   * for separating ranges of numbers, not
   * a regular dash.
   *
   * @see http://www.thepunctuationguide.com/en-dash.html
   * @returns {string}
   */
  get copyrightStatement(): string {
    if (this.currentYear === 2019) {
      return this.currentYear.toString();
    }

    return `2019–${this.currentYear.toString()}`;
  }
}
